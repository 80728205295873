
import React, { Component } from 'react';


export class PrProvider extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stateChange: 1,
      GetData: this.GetData,
      GetDetached: this.GetDetached,
      SetData: this.SetData,
      BroadCast: this.BroadCast
    }



    this._state = {
      prstate: props.store



    };





    // alert(JSON.stringify(this._state));
  }



  //GetData : this.GetData,


  //HandleIncrement : this.Increment,
  //HandleDecrement : this.Decrement,

  //BroadCast : this.BroadCast,


  GetDetached = (keyState) => {

    if (keyState === undefined) {
      let data = Object.assign({}, this._state.prstate);
      return data;
    }


    return Object.assign({}, this._state.prstate[keyState]);

  }


  GetData = (keyState) => {

    if (keyState === undefined)
      return this._state.prstate;


    return this._state.prstate[keyState];


  }

  /*
  GetStore = () => {
  
    if (keyState == undefined)
    return 
    this.state._state;
  
  
    return this.state._state.store[keyState];
  
  
  
  }
  */


  BroadCast = () => {

    // alert('in broadcast');
    this.setState({ stateChange: this.state.stateChange + 1 });


  }



  SetData = (keyState, newValue) => {

    //let _myState = this.state._state;
    //Object.assign()
    //let modifyState = {keyState:newValue};
    //alert(JSON.stringify(modifyState),null,4);

    let newState = Object.assign({}, this._state);
    newState.prstate[keyState] = newValue;
    this._state = newState;
    //alert('state changed ' +    JSON.stringify(newState) );

    //alert('state changed ' +    JSON.stringify(this.GetData()) );
    //this._state[keyState] = newState;

  }




  Increment = () => {
    //age = myGlobal.age 
    //alert('hi....');

    this.SetData('age', this.GetData('age') + 1);
    this.BroadCast();
  }
  Decrement = () => {

    this.SetData('age', this.GetData('age') - 1);
    this.BroadCast();

  }

  render() {
    // alert(this.state.stateChange);
    //alert( "IN pr Provide" + JSON.stringify(this.passToChildren,null,4));
    return (
      <div>


        <prContext.Provider value={this.state} >
          {
            this.props.children
          }
        </prContext.Provider>



      </div>
    );


  }

}

export const PrConnect = (Component, keyStates) =>
  class PrConnect extends React.Component {

    constructor(props) {
      super(props);
      this.keyStates = keyStates;

    }

    render() {
      return (

        <prContext.Consumer>
          {PrStore =>

            <div>
              {/* {"in consumer in connect" + JSON.stringify(PrStore.GetData('name'),null,4)} */}

              <ConnectFilter PrStore={PrStore} keyStates={this.keyStates} myComp={Component} componentprops={this.props} />
            </div>

          }
        </prContext.Consumer>
      );
    }
  }


export class ConnectFilter extends React.Component {
  constructor(props) {
    super(props);


    this.wetherStateChange = true;

    //alert(typeof(props.keyStates));
    this.keyStates = Array();
    this.newState = Array();
    if (Array.isArray((props.keyStates))) {
      // alert("in connect filter for component "+ "\n" + "array" + props.myComp );
      this.keyStates = props.keyStates;
    }
    else {
      //pr
      //alert("in connect filter for component " + "\n" + "string"+ props.myComp );
      this.keyStates[0] = props.keyStates;
      this.newState[0] = props.PrStore.GetData(this.keyStates[0]);


    }

    for (var i = 0; i < this.keyStates.length; i++) {

      this.newState[i] = props.PrStore.GetData(this.keyStates[i]);
    }

    // alert("in connect filter for component "+ props.myComp+ "\n" +JSON.stringify(this.newState));

    //this.newState[i] = nextProps.PrStore.GetData(this.keyStates[i]);

  }

  shouldComponentUpdate(nextProps) {

    this.wetherStateChange = false;
    this.keyStates = nextProps.keyStates;

    this.keyStates = Array();
    if (Array.isArray(nextProps.keyStates)) {
      this.keyStates = nextProps.keyStates;
    }
    else
      this.keyStates[0] = nextProps.keyStates;

    // this.newState = nextProps.prContext;

    //alert("this.props=" + JSON.stringify(this.props) + "\n" + "nextProps=" + JSON.stringify(nextProps));

    //alert(JSON.stringify(nextProps.PrStore.GetData(this.keyStates)) + "\n"+  JSON.stringify(this.newState));
    let flg = false;
    for (var i = 0; i < this.keyStates.length; i++) {

      //alert( JSON.stringify(nextProps.PrStore.GetData(this.keyStates[i])) + "\n"+ JSON.stringify(this.newState[i]) + nextProps.myComp );
      if (nextProps.PrStore.GetData(this.keyStates[i]) !== this.newState[i]) {

        this.wetherStateChange = true;
        this.newState[i] = nextProps.PrStore.GetData(this.keyStates[i]);

        //alert('true in ' + nextProps.myComp);
        flg = true;

      }
      else {
        //alert('false in ' + nextProps.myComp);
      }
    }
    return flg;

  }
  componentWillUpdate(nextProps) {
  }
  componentDidUpdate() {

  }

  componentDidMount() {
  }

  render() {

    if (this.wetherStateChange) {
      return (
        <this.props.myComp PrStore={this.props.PrStore} componentprops={this.props.componentprops} />
      );
    }
  }
}
export const prContext = React.createContext();