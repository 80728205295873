import React from 'react';
import { PrConnect } from '../pr-miniredux';
import { PrModalDialog } from '../pr-modal/pr-modal-dialog';

class Pr_Error extends React.Component {
    CloseModal = (modalId) => {
        document.getElementById(modalId).style.display = 'none';
    }

    CallBackOk = (modalId) => {
        document.getElementById(modalId).style.display = 'none';
        alert('in ok');

    }

    componentDidUpdate = (prevProps, prevState) => {

        if (this.props.PrStore.GetData('error').errorMessage !== '') {
            document.getElementById('prModal').style.display = 'block';
        }
    }


    render() {
        let prError = '';
        //PR
        // let prModalData = {
        //     prHeading: 'Error',
        //     prTitle: this.props.PrStore.GetData('error').errorCode,
        //     prMessege: this.props.PrStore.GetData('error').errorMessage,
        //     prettyMessage: this.props.PrStore.GetData('error').prettyMessage,
        //     CallBackCancel: this.CloseModal,
        //     CallBackOk: this.CallBackOk,
        //     prAjaxResponse: ''
        // };

        /*
        * prShowModal = prError , prConfirmModal , prAjaxModal
        *
        * */
        // alert("kk" + this.props.PrStore.GetData('error').errorMessage)
        if (this.props.PrStore.GetData('error').errorMessage !== '') {
            // alert(this.props.PrStore.GetData('error').errorMessage);
            prError = <PrModalDialog CallBackOk={this.CallBackOk} CallBackCancel={this.CloseModal} prModalData={''}
                prHeading={'Error'}
                prTitle={this.props.PrStore.GetData('error').errorCode}
                prMessege={this.props.PrStore.GetData('error').errorMessage}
                prettyMessage={this.props.PrStore.GetData('error').prettyMessage}
                prShowModal="dialog"
                prAjaxResponse={''} />;
        }
        else {
            // alert('no error');
            prError = <PrModalDialog prShowModal="false" />;
        }

        return (
            <div>
                {prError}
            </div>

        );
    }
}


export const Pr_Error_Ctrl = PrConnect(Pr_Error, "error");