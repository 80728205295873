import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './assets/font/Proxima-Nova-Alt-Bold.otf'
import './assets/font/Proxima-Nova-Alt-Light.otf'
import './assets/font/Proxima-Nova-Alt-Thin.otf'
import './assets/font/Proxima-Nova-Black.otf'
import './assets/font/Proxima-Nova-Bold.otf'
import './assets/font/Proxima-Nova-Extrabold.otf'
import './assets/font/Proxima-Nova-Thin.otf'
import './assets/font/ProximaNova-Regular.otf'
import './assets/css/style.css';
import Root from './routes/Routes.dev.js';
ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();
