import React, { Component } from 'react';
import Header from './Header';
import Logo from '../../assets/img/logo.png';
export default class HeaderComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            openSearch: false
        }
    }

    openSearch() {
        this.setState({
            openSearch: true
        })
    }
    closeSearch() {
        this.setState({
            openSearch: false
        })
    }
    componentDidMount() {
        // document.addEventListener("scroll", () => {
        //     localStorage.setItem("headH", document.getElementById("head").clientHeight)
        // });
        // window.scrollTo(0, 0);
    }

    render() {
        return (
            // <section className="head" id="head">
            <React.Fragment>
                <div className="container-fluid  j_heighthead" id="divStickyMenu">
                    <div className="row" style={{ margin: '0 auto', width: '96%', display: 'flex', alignItems: 'center', height: '100%' }}>
                        <a href="#" className="col-sm-6 col-md-2 col-lg-2 col-6" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                            {this.props.path == 'http://localhost:3000/' ?
                             
                                    <img className="lazyload" data-src={require("../../assets/images/" + this.props.logo)} alt={this.props.title} height="100%" width="50%" />
                              
                                :
                                <img className="lazyload" data-src={this.props.path + "images/" + this.props.logo} alt="Pin Click Logo" height="50%" width="50%" />
                            }
                            {/* <img src={require('../../assets/images/logo.jpg')} width="178px" height="70px" alt="img not found" /> */}
                        </a>
                        <div className="col-xs-8 col-sm-8 col-md-7 col-lg-8 j_telright hide-mob" style={{ height: '100%' }}>
                            <div className="row" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                <nav class="navbar navbar-expand-md header-nav" id='header-nav' style={{ height: '100%', display: 'flex', alignItems: 'center', padding: 0 }}>

                                    <ul class="navbar-nav" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                        <li class="nav-item header-margin-right header-margin" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                            <a className="nav-link j_anchor header-links" href="#">Home </a>
                                        </li>
                                        <li class="nav-item header-margin-right header-margin" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                            <a className="nav-link j_anchor header-links" href="#projects">Projects </a>
                                        </li>
                                        <li class="nav-item header-margin-right header-margin" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                            <a className="nav-link j_anchor header-links" href="#about">About Us </a>
                                        </li>
                                        {/* <li class="nav-item header-margin-right header-margin" style={{height:'100%',display:'flex',alignItems:'center'}}>
  <a href="#about" className="nav-link j_anchor header-links">Hiranandani</a>
  </li> */}
                                        <li class="nav-item header-margin-right header-margin" style={{ height: '100%', display: 'flex', alignItems: 'center' }}>
                                            <a className="nav-link j_anchor header-links" href="#contact-footer">Contact </a>
                                        </li>
                                        {/* <li class="nav-item header-margin-right" style={{height:'100%',display:'flex',alignItems:'center'}}>
  <a  className="nav-link j_anchor header-links"  onClick={()=>this.props.ShowBrochureModal()} style={{cursor:'pointer'}}>Download brochure </a>
  </li> */}


                                    </ul>

                                </nav>
                            </div>
                            {/* <div  >
                <div className="row">
                    <div className="col-auto header-padding-col">
                        <p className="j_stickyhead">
                            <a href="#" className="header-links">Home</a></p>
                    </div>
                    <div className="col-auto header-padding-col">
                        <p className="j_stickyhead">
                            <a href="#contact" className="header-links">Contact</a>
                        </p>
                    </div>
                    <div className="col-auto header-padding-col">
                        <p className="j_stickyhead">
                            <a href="#projects" className="header-links">Projects</a></p>
                    </div>
                    <div className="col-auto header-padding-col">
                        <p className="j_stickyhead">
                            <a href="#about" className="header-links">About Us</a></p>
                    </div>
                  
                   
                   
                    
                </div>
            </div> */}
                        </div>
                        <div className=" col-sm-2 col-md-3 col-lg-2 j_telright hide-mob">
                            <div className="j_telephone">
                                <i className="fa fa-phone  tele_icon mr-1" ></i><a href={"tel:+918047193000"} className="j_telanchor">+91-8047-193000</a>
                            </div>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3 col-lg-2 j_telright mob-only mob-style">
                            <div class="menu-container" id='menu-bar' onClick={(e) => { this.props.ShowSideBar(e) }}>
                                <div class="bar1"></div>
                                <div class="bar2"></div>
                                <div class="bar3"></div>
                            </div>
                        </div>
                    </div>

                </div>

                {this.state.openSearch === true ?
                    <div>
                        <div class="form-row u-search-div-res" >
                            <div class="input-group u-rectangle-15-search">
                                <input type="text" class=" u-global-search" type="text" placeholder="Search by city, builder name, project name..." />
                                <div class="input-group-prepend ">
                                    <span class="input-group-text u-shape" id="inputGroupPrepend3"><i onClick={() => this.closeSearch()} className="fa fa-close"></i></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    : ''}
            </React.Fragment>
            // </section>
        )
    }
}