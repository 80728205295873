import React, { Component, Suspense } from 'react';
// import '../../assets/css/u-shobhahome.css';
import axios from 'axios';

import HeaderComponent from '../../common/Header';
import Sidebar from '../../common/Sidebar';
import FooterComponent from '../../common/Footer';

import CallbackModal from './callback-modal'
// import Banner from './banner';

// import Contact from './contact'
// import About from './about'
// import SobhaDreamsAmenilities from './sobhaDreamsAmenilities';


// import AboutBuilder from './builder'

// import SideBar from '../../common/Sidebar'
import sha512 from 'js-sha512';

import $ from 'jquery'
import ContactModal from './contact_modal'
// const HeaderComponent = React.lazy(() => import('../../common/Footer'));
// const FooterComponent = React.lazy(() => import('../../common/Header'));
const Banner = React.lazy(() => import('./banner'));
const Contact = React.lazy(() => import('./contact'));
const SobhaDreamsAmenilities = React.lazy(() => import('./sobhaDreamsAmenilities'));
const AboutBuilder = React.lazy(() => import('./builder'));
const ContactFooter = React.lazy(() => import('./contact-footer'));
// const SideBar = React.lazy(() => import('../../common/Sidebar'));
export default class HomeCtrl extends Component {
    constructor(props) {
        super(props)
        this.state = {
            tabHead: [],
            arrow: 'true',
            //New end
            MOREPROJECT: [],
            cardModel: false,
            txtStickyDetailName: '',
            txtStickyDetailEmail: '',
            txtStickyDetailMobNo: '',
            ddlStickyDetailSel: '',
            IsPopupOpen: false,
            txtPopFormUserName: '',
            txtPopFormUserContact: '',
            txtPopFormUserEmail: '',
            txtCardModalFullName: '',
            txtCardModalUserEmail: '',
            txtCardModalUserContact: '',

            showSpinner: false,
            showOnLoadModal: false,
            txtContactUsername: '',
            txtContactEmail: '',
            txtContactPhone: '',
            IsPopupContactBtnEnable: true,
            mouseX: 0,
            mouseY: 0,
            popupCounter: 0,
            ddlProject: '',
            showOnMouseMove: '',
            showSidebar: false,
            banner_bottom_text: '',
            showCallbackModal: false,
            validationContactModalMessage: ''
        }
        this.images = [];
        this.server_path = window.location.protocol + '//' + window.location.host + window.location.pathname;
        this.db_server_path = 'https://pinclick.com/';

        //New
        this.tabHead = [];
        // this.contactMain = React.createRef();
        // this.fetchData();
    }

    componentDidMount = () => {
        let currentMousePosition = 0
        // else {
        //     document.body.scrollTop = 0;
        //     document.documentElement.scrollTop = 0;
        // }
        sessionStorage.removeItem("current_window")
        sessionStorage.removeItem("isSubmitContactModal")
        var that = this
        document.addEventListener("mousemove", function (e) {
            currentMousePosition = e.clientY
            // that.setState({
            //     mouseX : e.clientX,
            //     mouseY : e.clientY
            // })


        });

        $(document).mouseleave(function () {
            if (currentMousePosition < 100) {

                // that.setState({
                //     popupCounter:that.state.popupCounter+1
                // })
                if (sessionStorage.getItem("isSubmitContactModal") !== 'submitted') {

                    if (that.state.showOnMouseMove !== 'hide' && that.state.showOnMouseMove === '') {
                        that.setState({ showOnLoadModal: true, showOnMouseMove: 'show' })
                    }
                }
            }
        });
    }

    UNSAFE_componentWillMount = () => {

        if (typeof window.orientation !== 'undefined') {
            this.setState({
                arrow: false
            })
        }
        // my code----------------------------------------------------------------------

        // my code----------------------------------------------------------------------


        /*-----------------------------XML READING--------------------------------------*/


        const rawFile = new XMLHttpRequest();
        let file = '';

        if (this.server_path === 'http://localhost:3000/') {
            file = require('./Data.xml')
        }
        else {
            //alert(window.location.protocol + '//' + window.location.host + window.location.pathname);
            // file = require('./Data.xml');
            file = this.server_path + 'Data.xml';
        }


        rawFile.onreadystatechange = () => {
            if (rawFile.readyState === 4 && (rawFile.status === 200 || rawFile.status === 0)) {
                const parser = new DOMParser();
                const xml = parser.parseFromString(rawFile.response, 'text/xml');


                let title = xml.getElementsByTagName('TITLE')[0].childNodes[0].nodeValue;
                document.title = title



                localStorage.setItem("title_main", title)

                let pin_logo = xml.getElementsByTagName('PINLOGO')[0].childNodes[0].nodeValue;

                let logo = xml.getElementsByTagName('PROJECTLOGO')[0].childNodes[0].nodeValue;
                let logo_alt = xml.getElementsByTagName('PROJECTLOGOALT')[0].childNodes[0].nodeValue;
                let logo_title = xml.getElementsByTagName('PROJECTLOGOTITLE')[0].childNodes[0].nodeValue;

                let builder_contact = xml.getElementsByTagName('BUILDERCONTACT')[0].childNodes[0].nodeValue;

                console.log('111111');

                let about_pinclick = '';
                // let about_pinclick = xml.getElementsByTagName('ABOUTPINCLICK')[0].childNodes[0].nodeValue;
                if (about_pinclick.length < 20) {
                    about_pinclick = "Pin Click: We are a ZERO brokerage platform with a mission is to simplify the home search."
                    about_pinclick += "\nWe pride ourselves in our technology, people and process enabled solutions to help customers search, view and move into their Dream home."
                    about_pinclick += "\nAs Marketing Partners to top developers in Bengaluru, Pune, Gurgaon and Mumbai we have supported over 1500 customers in finding their Dream homes since 2013."
                    about_pinclick += "\nOur Team of property advisors help customers through the journey of buying a home - right from sharing property details, face to face or video conference driven discussions, site visits to shortlisted projects, inventory support and best price possible.";
                }

                console.log('22222');

                let meta = xml.getElementsByTagName('META');
                let meta_title = '', meta_keyword = '', meta_description = '';
                for (let i = 0; i < meta.length; i++) {
                    var objectElem = meta[i];
                    meta_title = objectElem.getElementsByTagName("MetaTitle")[0].getAttribute('title');
                    meta_description = (objectElem.getElementsByTagName("MetaDescription")[0].getAttribute('description'));
                    meta_keyword = (objectElem.getElementsByTagName("MetaKeyword")[0].getAttribute('Keyword'));
                    //par = '<img class="item" href="#" ondblclick="confirm()" src="'+ a +'" title="' +b+ '-'+ c+ '" />';
                    // alert("=="+meta_title + "--" + meta_description + "--" + meta_keyword);
                }



                let main_banner = xml.getElementsByTagName('BANNERIMAGE')[0].childNodes[0].nodeValue;
                let banner_alt = xml.getElementsByTagName('BANNERIMAGEALT')[0].childNodes[0].nodeValue;
                let banner_title = xml.getElementsByTagName('BANNERTITLE')[0].childNodes[0].nodeValue;
                let banner_desc = xml.getElementsByTagName('BANNERDESCRIPTION')[0].childNodes[0].nodeValue;



                // let banner_experiance = xml.getElementsByTagName('BANNEREXPERIANCE')[0].childNodes[0].nodeValue;
                // let banner_total_projects = xml.getElementsByTagName('BANNERTOTALPROJECTS')[0].childNodes[0].nodeValue;
                // let banner_total_ongoing = xml.getElementsByTagName('BANNERTOTALONGOING')[0].childNodes[0].nodeValue;
                let project_head = xml.getElementsByTagName('PROJECTHEAD')[0].childNodes[0].nodeValue;



                let builder = xml.getElementsByTagName('BUILDER');
                let builder_name = '', builder_description = '', builder_id = '', builder_experiance = 5, builder_total = 5, builder_ongoing = 0;
                for (let i = 0; i < builder.length; i++) {
                    var objectElem = builder[i];
                    builder_name = (objectElem.getElementsByTagName("BUILDERNAME")[0].textContent);
                    builder_description = (objectElem.getElementsByTagName("BUILDERDESCRIPTION")[0].textContent);
                    builder_id = (objectElem.getElementsByTagName("BUILDEID")[0].textContent);

                    if (objectElem.getElementsByTagName("BUILDEREXPERIANCE")[0] !== undefined && objectElem.getElementsByTagName("BUILDEREXPERIANCE")[0] !== null && objectElem.getElementsByTagName("BUILDEREXPERIANCE")[0] !== '')
                        builder_experiance = (objectElem.getElementsByTagName("BUILDEREXPERIANCE")[0].textContent);
                    if (objectElem.getElementsByTagName("BUILDERPROJECT")[0] !== undefined && objectElem.getElementsByTagName("BUILDERPROJECT")[0] !== null && objectElem.getElementsByTagName("BUILDERPROJECT")[0] !== '')
                        builder_total = (objectElem.getElementsByTagName("BUILDERPROJECT")[0].textContent);
                    if (objectElem.getElementsByTagName("BUILDERONGOINGPROJECT")[0] !== undefined && objectElem.getElementsByTagName("BUILDERONGOINGPROJECT")[0] !== null && objectElem.getElementsByTagName("BUILDERONGOINGPROJECT")[0] !== '')
                        builder_ongoing = (objectElem.getElementsByTagName("BUILDERONGOINGPROJECT")[0].textContent);
                }




                let offer_details = [];
                let offer_head = '', offer_body = '', offer_footer = '';
                if (xml.getElementsByTagName('OFFER') !== undefined && xml.getElementsByTagName('OFFER') !== null && xml.getElementsByTagName('OFFER') !== '') {
                    let offer_details = xml.getElementsByTagName('OFFER');


                    for (let i = 0; i < offer_details.length; i++) {
                        var objectElem = offer_details[i];
                        if (objectElem.getElementsByTagName("OFFERHEAD")[0] !== undefined)
                            offer_head = (objectElem.getElementsByTagName("OFFERHEAD")[0].textContent)
                        if (objectElem.getElementsByTagName("OFFERBODY")[0] !== undefined)
                            offer_body = (objectElem.getElementsByTagName("OFFERBODY")[0].textContent)
                        if (objectElem.getElementsByTagName("OFFERFOOTER")[0] !== undefined)
                            offer_footer = (objectElem.getElementsByTagName("OFFERFOOTER")[0].textContent);
                    }
                }




                let projects = xml.documentElement.getElementsByTagName('DDLPROJECTS');
                let projects_items = projects[0].getElementsByTagName('Item');
                let projects_arr = [];
                for (let i = 0; i < projects_items.length; i++) {
                    if (projects_items[i].getElementsByTagName("Name")[0].textContent !== '')
                        projects_arr.push(projects_items[i].getElementsByTagName("Name")[0].textContent);

                }




                let all_city_list = []
                if (xml.getElementsByTagName('CITYLIST') !== undefined && xml.getElementsByTagName('CITYLIST') !== null && xml.getElementsByTagName('CITYLIST') !== '') {
                    let city_names = xml.getElementsByTagName('CITYLIST');
                    if (city_names[0] !== undefined && city_names[0] !== null && city_names[0] !== '') {
                        let city_list = city_names[0].getElementsByTagName('CITY');
                        for (let i = 0; i < city_list.length; i++) {
                            var objectElem = city_list[i];
                            if (objectElem.textContent !== '')
                                all_city_list.push(objectElem.textContent)
                        }
                    }
                }

                let city_name_arr = [];
                let city_name = xml.getElementsByTagName('TABHEAD');
                if (city_name[0] !== undefined && city_name[0] !== null && city_name[0] !== '') {
                    let cities = city_name[0].getElementsByTagName('CATEGORY');
                    for (let i = 0; i < cities.length; i++) {
                        var objectElem = cities[i];
                        if (objectElem.textContent !== '')
                            city_name_arr.push(objectElem.textContent)
                    }
                }


                let project_list = xml.documentElement.getElementsByTagName('PROJECTS');
                let projects_cities = project_list[0].getElementsByTagName('CITIES');
                let projects_cities_arr = [];
                for (let i = 0; i < projects_cities.length; i++) {
                    let city_names = city_name_arr[i];
                    if (city_names !== undefined && city_names !== null && city_names !== '') {
                        if (projects_cities[i] !== undefined && projects_cities[i] !== null && projects_cities[i] !== '') {
                            let proj_items = projects_cities[i].getElementsByTagName('Item');
                            for (let j = 0; j < proj_items.length; j++) {
                                let proj_img = proj_items[j].getElementsByTagName("Image")[0].textContent;
                                let proj_unit_config = '';
                                if (proj_items[j].getElementsByTagName("UNITCONFIG")[0] !== undefined && proj_items[j].getElementsByTagName("UNITCONFIG")[0] !== null && proj_items[j].getElementsByTagName("UNITCONFIG")[0] !== '')
                                    proj_unit_config = proj_items[j].getElementsByTagName("UNITCONFIG")[0].textContent;
                                let proj_price_range = '';
                                if (proj_items[j].getElementsByTagName("PriceRange")[0] !== undefined && proj_items[j].getElementsByTagName("PriceRange")[0] !== null && proj_items[j].getElementsByTagName("PriceRange")[0] !== '')
                                    proj_price_range = proj_items[j].getElementsByTagName("PriceRange")[0].textContent;
                                let proj_img_alt = proj_items[j].getElementsByTagName("ImageAlt")[0].textContent;
                                let proj_img_title = proj_items[j].getElementsByTagName("ImageTitle")[0].textContent;
                                let proj_title = proj_items[j].getElementsByTagName("Title")[0].textContent;
                                let proj_price = proj_items[j].getElementsByTagName("Price")[0].textContent;
                                let proj_addr = proj_items[j].getElementsByTagName("Address")[0].textContent;
                                let proj_Rera = proj_items[j].getElementsByTagName("Rera")[0].textContent;
                                let proj_Status = proj_items[j].getElementsByTagName("Status")[0].textContent;
                                let proj_Bedroom = proj_items[j].getElementsByTagName("Bedroom")[0].textContent;
                                let proj_link = proj_items[j].getElementsByTagName("ProjectLink")[0].textContent;


                                let unit_pricing = proj_items[j].getElementsByTagName('ProjectPricing');
                                let units = unit_pricing[0].getElementsByTagName('Units');

                                let sp_details = proj_items[j].getElementsByTagName('SellingPrice');

                                let sp = []
                                if (sp_details.length > 0) {
                                    sp = sp_details[0].getElementsByTagName('SP');
                                }

                                let units_arr = [];
                                let sp_arr = [];

                                for (let k = 0; k < units.length; k++) {
                                    units_arr.push({ 'unit': units[k].getElementsByTagName("UnitType")[0].textContent, 'size': units[k].getElementsByTagName("UnitSize")[0].textContent, 'price': units[k].getElementsByTagName("UnitPrice")[0].textContent, 'proj_Type': units[k].getElementsByTagName("Type")[0].textContent })
                                }
                                for (let k = 0; k < sp.length; k++) {
                                    sp_arr.push({ 'sp_value': sp[k].getElementsByTagName("SPValue")[0].textContent, 'sp_type': sp[k].getElementsByTagName("SPType")[0].textContent, 'sp_duration': sp[k].getElementsByTagName("SPDuration")[0].textContent })
                                }
                                let cityIndex = 0
                                if (projects_cities_arr !== undefined) {
                                    cityIndex = projects_cities_arr.findIndex(item => city_names == item.city);
                                    if (cityIndex == -1) {
                                        // alert('not available')
                                        if (proj_img !== '' && i == 0)
                                            projects_cities_arr.push({ 'city': city_names, uniqu_name: city_names.replace(/\s/g, "-"), active: 'show active', list: [{ 'image': proj_img, 'img_alt': proj_img_alt, 'img_title': proj_img_title, 'proj_title': proj_title, 'proj_price': proj_price, 'proj_addr': proj_addr, 'proj_Rera': proj_Rera, 'proj_Status': proj_Status, 'proj_Bedroom': proj_Bedroom, 'proj_link': proj_link, 'sp_details': sp_arr, 'subdetails': units_arr, 'rera': 'RERA ✓', 'heart': 'fa fa-heart', 'proj_unit_config': proj_unit_config, 'proj_price_range': proj_price_range }] });
                                        else if (proj_img !== '')
                                            projects_cities_arr.push({ 'city': city_names, uniqu_name: city_names.replace(/\s/g, "-"), active: '', list: [{ 'image': proj_img, 'img_alt': proj_img_alt, 'img_title': proj_img_title, 'proj_title': proj_title, 'proj_price': proj_price, 'proj_addr': proj_addr, 'proj_Rera': proj_Rera, 'proj_Status': proj_Status, 'proj_Bedroom': proj_Bedroom, 'proj_link': proj_link, 'sp_details': sp_arr, 'subdetails': units_arr, 'rera': 'RERA ✓', 'heart': 'fa fa-heart', 'proj_unit_config': proj_unit_config, 'proj_price_range': proj_price_range }] });
                                    }
                                    else {
                                        // alert('available')
                                        if (proj_img !== '')
                                            projects_cities_arr[cityIndex].list.push({ 'image': proj_img, 'img_alt': proj_img_alt, 'img_title': proj_img_title, 'proj_title': proj_title, 'proj_price': proj_price, 'proj_addr': proj_addr, 'proj_Rera': proj_Rera, 'proj_Status': proj_Status, 'proj_Bedroom': proj_Bedroom, 'proj_link': proj_link, 'sp_details': sp_arr, 'subdetails': units_arr, 'rera': 'RERA ✓', 'heart': 'fa fa-heart', 'proj_unit_config': proj_unit_config, 'proj_price_range': proj_price_range });
                                        // SalesUserArr[cityIndex].list.push({ 'tl_name': sales.tl_name, 'sales_user': sales.sales_user, 'sales_today_meetings': sales_today_meetings, 'sales_yesterday_meetings': sales_yesterday_meetings, 'sales_this_month_meetings': month_ratio, 'sales_this_month_meetings1': sales_this_month_meetings });
                                    }
                                }
                            }
                        }
                    }
                }



                this.setState({
                    title: title,
                    pin_logo: pin_logo,

                    logo: logo,
                    logo_alt: logo_alt,
                    logo_title: logo_title,

                    builder_contact: builder_contact,
                    about_pinclick: about_pinclick,

                    main_banner: main_banner,
                    banner_alt: banner_alt,
                    banner_title: banner_title,
                    banner_desc: banner_desc,

                    // banner_experiance: banner_experiance,
                    // banner_total_projects: banner_total_projects,
                    // banner_total_ongoing: banner_total_ongoing,

                    builder_name: builder_name,
                    builder_description: builder_description,
                    builder_id: builder_id,
                    builder_experiance: builder_experiance,
                    builder_total: builder_total,
                    builder_ongoing: builder_ongoing,

                    project_head: project_head,

                    projects_arr: projects_arr,

                    city_name_arr: city_name_arr,
                    projects_cities_arr: projects_cities_arr,
                    offer_head: offer_head,
                    offer_body: offer_body,
                    offer_footer: offer_footer,
                    all_city_list: all_city_list
                })

            }


            if (sessionStorage.getItem("contact_modal") === "" || sessionStorage.getItem("contact_modal") === undefined || sessionStorage.getItem("contact_modal") === null) {
                sessionStorage.setItem("contact_modal", "hide")

                setTimeout(() => {

                    this.setState({
                        showOnLoadModal: true
                    })

                }, 2000);
            }
            else {
                sessionStorage.removeItem("contact_modal")
            }


        };
        rawFile.open('GET', file, false);
        rawFile.send();



    }

    callbackFunctionChange = () => {
        if (this.state.arrow == 'true') {
            this.setState({
                arrow: 'false'
            })
        }
        else {
            this.setState({
                arrow: 'true'
            })
        }
    }
    callbackFunction = (name, email, mobile, project, comment, city, from = 'main') => {
        console.log("from", from)
        this.setState({
            showSpinner: true
        });
        let mobile_no = mobile;

        let utm_source = "Organic";
        if (from === "modal-popup") {
            utm_source = 'PopUp'
        }

        let utm_term = "";
        let details_url = window.location.href;
        if (details_url.indexOf('utm_source') > -1) {
            let newUrl = details_url.split("utm_source=")
            newUrl = newUrl[1]
            if (newUrl.includes('&'))
                utm_source = newUrl.substring(newUrl, newUrl.indexOf("&"));
            else
                utm_source = newUrl
            utm_source = utm_source.replace(/%2B/g, '')
            utm_source = utm_source.replace(/%20/g, ' ')
        }
        if (details_url.indexOf('utm_term') > -1) {
            let newUrl1 = details_url.split("utm_term=")
            newUrl1 = newUrl1[1]
            if (newUrl1.includes('&'))
                utm_term = newUrl1.substring(newUrl1, newUrl1.indexOf("&"));
            else
                utm_term = newUrl1

            utm_term = utm_term.replace(/%2B/g, '')
            utm_term = utm_term.replace(/%20/g, ' ')
        }

        let data = {
            user_name: name,
            user_email: email,
            user_contact: mobile,
            prop_name: project,
            comment: comment,
            city: city,
            utm_source: utm_source,

        }
        console.log("data", data)
        if (utm_term !== '')
            data.keyword = utm_term;

        let that = this;
        console.log("data", data)
        axios.post(this.db_server_path + 'admin/property/leadrequest/json/outside', data)
            .then(function (response) {
                if (sessionStorage.getItem("current_window") !== undefined || sessionStorage.getItem("current_window") !== null || sessionStorage.getItem("current_window") !== '') {

                    sessionStorage.setItem("isSubmitContactModal", 'submitted')
                    that.setState({
                        showOnLoadModal: false
                    })

                }
                if (from === 'main') {
                    sessionStorage.setItem('current_url', '');
                    that.setState({

                        showSpinner: false
                    })


                    let url = window.location.href;

                    url = url.split('?')[0];
                    var lastChar = url.substr(-1); // Selects the last character
                    if (lastChar != '/') {         // If the last character is not a slash
                        url = url + '/successful/?' + sha512(mobile_no);            // Append a slash to it.
                    }
                    else
                        url = url + 'successful/?' + sha512(mobile_no);


                    window.location.href = url;

                }
                else {
                    that.setState({
                        showSpinner: false
                    })
                }
            })
            .catch(function (error) {
                console.log(error);

                that.setState({
                    showSpinner: false
                })
            });
    }
    // GoToLearnMore = (page) => {
    //     this.props.history.push("learn-more-" + page);
    // }

    HandleSorting = (e) => {
        //e.target.name: e.target.value
        // alert(e.target.value);
        let aa = '';
        if (this.state.aa === undefined)
            aa = this.state.projects_cities_arr.length
        let arr = [];
        if (e.target.value === 'Name')
            (this.state.projects_cities_arr).forEach(element => {
                // alert(JSON.stringify(element.list))
                element.list.sort((a, b) => (a.proj_title > b.proj_title) ? 1 : ((b.proj_title > a.proj_title) ? -1 : 0));
                arr.push(element)
            });
        else
            (this.state.projects_cities_arr).forEach(element => {
                // alert(JSON.stringify(element.list))
                element.list.sort((a, b) => (a.proj_addr > b.proj_addr) ? 1 : ((b.proj_addr > a.proj_addr) ? -1 : 0));
                arr.push(element)
            });
        // alert(JSON.stringify(arr))
        this.setState({
            projects_cities_arr: arr,
            aa: aa + 1
        })
        this.forceUpdate();
        // alert(JSON.stringify(this.state.projects_cities_arr))
    }
    HandleChangeContactModal = (e) => {

        const value = e.target.value;
        const name = e.target.name;

        const emailExpn = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numbers = /^[0-9]+$/;
        if (name === 'txtContactUsername') {
            if (value === '') {
                this.setState({
                    validationContactModalMessage: 'Enter Name',
                    [name]: ''
                })
                return false
            }
            else {
                this.setState({
                    [name]: value,
                    validationContactModalMessage: ''
                })
            }
        }
        else if (name === 'txtContactEmail') {
            if (value !== '') {
                this.setState({
                    [name]: value
                })
                if (emailExpn.test(value) !== true) {
                    this.setState({
                        validationContactModalMessage: 'Enter Valid Email',
                        [name]: value
                    })
                    return false
                }
                else {
                    this.setState({
                        validationContactModalMessage: '',
                        [name]: value
                    })
                }
            }
            else {
                this.validationContactModalMessage({
                    validationError: 'Enter Valid Email',
                    [name]: value
                })
                return false
            }


        }

        else if (name === 'txtContactPhone') {
            if (value !== '') {
                if (!value.match(numbers)) {
                    this.setState({
                        [name]: value,
                        validationContactModalMessage: 'Enter Valid Mobile Number',
                    })
                    return false
                }
                else {
                    this.setState({
                        [name]: value,
                        validationContactModalMessage: '',
                    })
                }
            }
            else {
                this.setState({
                    [name]: value,
                    validationContactModalMessage: 'Enter Valid Mobile Number',
                })
                return false
            }
        }
        if (name === 'ddlProject') {
            if (value === '') {
                this.setState({
                    validationContactModalMessage: 'Select Project',
                    [name]: ''
                })
                return false
            }
            else {
                this.setState({
                    [name]: value,
                    validationContactModalMessage: ''
                })
            }
        }
    }
    HideOnLoadModal = () => {
        if (this.state.showOnMouseMove === 'show') {
            this.setState({
                showOnMouseMove: 'hide',

            })
        }
        this.setState({ showOnLoadModal: false })
    }
    SendContactModalMail = () => {
        const emailExpn = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numbers = /^[0-9]+$/;
        if (this.state.txtContactUsername === '') {
            this.setState({
                validationContactModalMessage: 'Enter Name',

            })
            return false
        }
        else {
            this.setState({
                validationContactModalMessage: '',

            })
        }
        if (this.state.txtContactEmail === '') {
            this.setState({
                validationContactModalMessage: 'Enter Valid Email',

            })
            return false
        }
        else {
            if (emailExpn.test(this.state.txtContactEmail) !== true) {
                this.setState({
                    validationContactModalMessage: 'Enter Valid Email',

                })
                return false
            }
            else {
                this.setState({
                    validationContactModalMessage: '',

                })
            }
        }

        if (this.state.txtContactPhone === '') {
            this.setState({
                validationContactModalMessage: 'Enter Valid Mobile Number',

            })
            return false
        }
        else {
            if (!this.state.txtContactPhone.match(numbers)) {
                this.setState({
                    validationContactModalMessage: 'Enter Valid Mobile Number',

                })
                return false
            }
            else if (this.state.txtContactPhone.length !== 10) {
                this.setState({
                    validationContactModalMessage: 'Enter Valid Mobile Number',

                })
                return false
            }
            else {
                this.setState({
                    validationContactModalMessage: '',

                })
            }
        }
        if (this.state.ddlProject === '') {
            this.setState({
                validationContactModalMessage: 'Select Project',

            })
            return false
        }
        else {
            this.setState({
                validationContactModalMessage: '',

            })
        }





        if (this.state.validationContactModalMessage === '') {

            this.setState({
                showSpinner: true,
                IsPopupContactBtnEnable: false
            })

            let data = {
                user_name: this.state.txtContactUsername,
                user_email: this.state.txtContactEmail,
                user_contact: this.state.txtContactPhone,
                prop_name: this.state.ddlProject,
                utm_source: ''
            }
            sessionStorage.setItem("current_window", 'contact_modal')
            console.log("contact_modal", data)
            this.callbackFunction(this.state.txtContactUsername, this.state.txtContactEmail, this.state.txtContactPhone, this.state.ddlProject, '', '', 'modal-popup')
        }
        // this.SendingLeadMail(data, 'modal');
    }
    ShowSideBar = (x) => {
        var element = document.getElementById("projectRoot");
        element.classList.toggle("change");
        this.setState({
            showSidebar: !this.state.showSidebar
        })
    }
    ShowCallbackModal = () => {
        this.setState({
            showCallbackModal: true
        })
    }
    HideCallbackModal = () => {
        this.setState({
            showCallbackModal: false
        })
    }
    render() {

        return (
            <React.Fragment>
                {this.state.showSidebar === true ?
                    <Sidebar path={this.server_path} logo={this.state.logo} title={this.state.title} builder_contact={this.state.builder_contact} ShowSideBar={this.ShowSideBar} /> : null}
                <HeaderComponent path={this.server_path} logo={this.state.logo} title={this.state.title} builder_contact={this.state.builder_contact} ShowSideBar={this.ShowSideBar} />
                <div id="projectRoot">
                    {this.state.showSpinner ?
                        <div class="text-center">
                            <div id="overlay">
                                <div class="spinner-border text-primary" role="status">
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </div>
                        </div>
                        : ''}


                    <Suspense fallback={<img src={require('./pin_loader.gif')} style={{ width: '150px', marginLeft: '40%', marginTop: '10%' }} />}>


                        <div class="main1" >
                            {/* <Banner offer_head={this.state.offer_head} offer_body={this.state.offer_body} offer_footer={this.state.offer_footer} path={this.server_path} {...this.props} parentCallbackChange={this.callbackFunctionChange} BannerImg={this.state.main_banner} arrow={this.state.arrow} banner_experiance={this.state.banner_experiance} banner_total_projects={this.state.banner_total_projects} banner_total_ongoing={this.state.banner_total_ongoing} change={this.state.change} banner_alt={this.state.banner_alt} banner_title={this.state.banner_title} banner_desc={this.state.banner_desc} /> */}
                            <Banner offer_head={this.state.offer_head} offer_body={this.state.offer_body} offer_footer={this.state.offer_footer} path={this.server_path} {...this.props} parentCallbackChange={this.callbackFunctionChange} BannerImg={this.state.main_banner} arrow={this.state.arrow} change={this.state.change} banner_alt={this.state.banner_alt} banner_title={this.state.banner_title} banner_desc={this.state.banner_desc} />
                        </div>
                        <div className='main2'  >
                            <Contact ShowCallbackModal={this.ShowCallbackModal} ref={this.contactMain} {...this.props} title={this.state.title} HandleSorting={this.HandleSorting} path={this.server_path} projects={this.state.projects_arr} {...this.props} tabHead={this.state.projects_cities_arr} parentCallback={this.callbackFunction} />
                        </div>

                        <div class="main3">
                            {/* <SobhaDreamsAmenilities {...this.props} tabHead={this.tabHead} parentCallback={this.callbackFunction} /> */}
                            <SobhaDreamsAmenilities builder_name={this.state.builder_name} HandleSorting={this.HandleSorting} path={this.server_path} projects={this.state.projects_arr} {...this.props} tabHead={this.state.projects_cities_arr} parentCallback={this.callbackFunction} />
                        </div>
                        <div>
                            <AboutBuilder {...this.props} builder_name={this.state.builder_name} builder_description={this.state.builder_description} builder_experiance={this.state.builder_experiance} builder_total={this.state.builder_total} builder_ongoing={this.state.builder_ongoing} />
                        </div>
                        <div>
                            <ContactFooter all_city_list={this.state.all_city_list} path={this.server_path} BannerImg={this.state.main_banner} {...this.props} {...this.props} parentCallback={this.callbackFunction} projects={this.state.projects_arr} />
                        </div>

                        <div >
                            <FooterComponent path={this.server_path} about_pinclick={this.state.about_pinclick} logo={this.state.logo} title={this.state.title} />
                        </div>
                    </Suspense>


                </div>

                {this.state.showOnLoadModal === true ?
                    <ContactModal validationContactModalMessage={this.state.validationContactModalMessage} visible='block' HandleClose={this.HideOnLoadModal} mystate={this.state} IsPopupContactBtnEnable={this.state.IsPopupContactBtnEnable} path={this.server_path}
                        txtContactUsername={this.state.txtContactUsername} txtContactEmail={this.state.txtContactEmail} txtContactPhone={this.state.txtContactPhone} HandleChangeContactModal={this.HandleChangeContactModal} logo={this.state.logo}
                        SendContactModalMail={this.SendContactModalMail} projects={this.state.projects_arr}
                    /> : null}
                {
                    this.state.showCallbackModal === true ?
                        <CallbackModal HideCallbackModal={this.HideCallbackModal} parentCallback={this.callbackFunction} projects={this.state.projects_arr} /> : null
                }
            </React.Fragment>
        )
    }
}