import React, { Component } from 'react';
export default class Header extends Component {
    constructor(props) {
        super(props);
        this.state={
         openSearch:false,
        }
    }
    openSearch()
    {
       this.setState({
         openSearch:true
       })
    }
    closeSearch()
    {
       this.setState({
         openSearch:false
       })
    }
    componentDidMount() {
      // document.addEventListener("scroll", () => {
      //    localStorage.setItem("headH",document.getElementById("head").clientHeight)
      // });
      // window.scrollTo(0, 0);
  }

    render() {
        return (
            <section className="head" id="head">
            <nav class="navbar navbar-expand-lg navbar-light u-rectangle-2-nav">
              {/* <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                 <span class="navbar-toggler-icon"></span>
              </button> */}
                 <div className="container">
                    <div className="row">
                       <div className="col-lg-3 col-md-3 col-sm-3 col-xs-1 logo">
                          <a class="navbar-brand u-bitmap-logo" href="#">
                             <img src={this.props.logo} />
                             </a>
                             </div>
                          <div className="col-lg-6 col-md-6 col-sm-5 col-xs-6 search">
                             <div class="form-row u-search-div">
                                <div class="input-group u-rectangle-15-search">
                                   <div class="input-group-prepend ">
                                      <span class="input-group-text u-shape" id="inputGroupPrepend3"><i className="fa fa-search"></i></span>
                                   </div>
                                      <input type="text" class="u-global-search" type="search" placeholder="Search by city, builder name, project name..." />
                                   </div>
                             </div>
                             {this.state.openSearch===true?'':
                             <i onClick={()=>this.openSearch()} style={{display:'none'}} className="res-search fa fa-search"></i>
                             }
                          </div>
                          <div className="col-lg-3 col-md-3 col-sm-4 col-xs-5 contact">
                             <div class="form-inline my-2 my-lg-0 u-rectangle-8 u-rectangle-8-contact">
                                 <span className="u-1800-3000-5245"><i className="u-shape-mob fa fa-phone"></i>{this.props.mobile}</span>
                             </div>
                             {this.state.openSearch===true?'':
                             <i  style={{display:'none'}} data-toggle="modal" data-target="#exampleModal" className="contact-no u-shape-mob fa fa-phone"></i>
                                  }
                             </div>
                       </div>
                    </div>      
                    </nav>
                    {this.state.openSearch===true?
                    <div>
                       <div class="form-row u-search-div-res" >
                                <div class="input-group u-rectangle-15-search">
                                      <input type="text" class=" u-global-search" type="text" placeholder="Search by city, builder name, project name..." />
                                   <div class="input-group-prepend ">
                                      <span class="input-group-text u-shape" id="inputGroupPrepend3"><i onClick={()=>this.closeSearch()} className="fa fa-close"></i></span>
                                   </div>
                                   </div>
                             </div>
                    </div>
                             :''}
 
         </section>
        )
    }
}