import React from 'react'
export default class CallbackModal extends React.Component{
    constructor(props){
        super(props)
        this.state={
            name: '',
            email: '',
            mobile: '',
            project: '',
            buttonStage: true,
            ddlCallbackType: '',
            txtCallbackDate: '',
            showPicker: false,
            selectedDate: '',
            currentTime: '',
            currentDate: '',
            selectedTime: '',
            validationError:''
        }
    }
    HandleChange = (e) => {

        const value  = e.target.value;
        const name = e.target.name;
       
        const emailExpn = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numbers = /^[0-9]+$/;
        if (name === 'name') {
            if(value===''){
                this.setState({
                    validationError:'Enter Name',
                    [name]:''
                })
                return false
            }
            else{
                this.setState({
                    [name]:value,
                    validationError:''
                })
            }
        }
        else if (name === 'email') {
            if(value!==''){
                this.setState({
                    [name]: value
                })
             if(emailExpn.test(value) !==true){
                this.setState({
                    validationError:'Enter Valid Email',
                    [name]: value
                })
                return false  
            }
            else{
                this.setState({
                    validationError:'',
                    [name]: value
                })
            }
        }
        else{
            this.setState({
                validationError:'Enter Valid Email',
                [name]: value
            })
            return false
        }

            
        }
       
        else if (name === 'mobile') {
            if(value!==''){
                if (!value.match(numbers) ) {
                    this.setState({
                        [name]: value,
                        validationError:'Enter Valid Mobile Number',
                    })
                    return false
                }
                else{
                    this.setState({
                        [name]: value,
                        validationError:'',
                    })
                }
            }
            else{
                this.setState({
                    [name]: value,
                    validationError:'Enter Valid Mobile Number',
                })
                return false
            }
        }
        if (name === 'project') {
            if(value===''){
                this.setState({
                    validationError:'Select Project',
                    [name]:''
                })
                return false
            }
            else{
                this.setState({
                    [name]:value,
                    validationError:''
                })
            }
        }

    }
  
   
     Submit = () => {
        const emailExpn = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        var numbers = /^[0-9]+$/;
      if(this.state.name===''){
          this.setState({
              validationError:'Enter Name',
            
          })
          return false
      }
      else{
        this.setState({
            validationError:'',
          
        })
      }
      if(this.state.email===''){
        this.setState({
            validationError:'Enter Valid Email',
           
        })
        return false
      }
      else{
        if(emailExpn.test(this.state.email) !==true){
            this.setState({
                validationError:'Enter Valid Email',
              
            })
            return false  
        }
        else{
            this.setState({
                validationError:'',
               
            })
        }
      }

      if(this.state.mobile===''){
        this.setState({
            validationError:'Enter Valid Mobile Number',
            
        })
        return false
      }
      else{
        if (!this.state.mobile.match(numbers) ) {
            this.setState({
                validationError:'Enter Valid Mobile Number',
               
            })
            return false  
        }
        else if(this.state.mobile.length!==10){
            this.setState({
                validationError:'Enter Valid Mobile Number',
                
            })
            return false  
        }
        else{
            this.setState({
                validationError:'',
               
            })
        }
      }
      if(this.state.project===''){
        this.setState({
            validationError:'Select Project',
          
        })
        return false
    }
    else{
      this.setState({
          validationError:'',
        
      })
    }
       if(this.state.validationError===''){
        this.props.parentCallback(this.state.name, this.state.email, this.state.mobile, this.state.project,'','')
       }
    }
    render(){
        return(
               
            <div class="modal" id="callbackModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" style={{ display:'block',background:'rgba(0,0,0,0.5)' }}>
            <div class="modal-dialog modal-md modal-dialog-centered" role="document">
               
                    <div class="modal-content brochure-modal-content">
<div class="modal-header brochure-modal-header">
<h5 class="modal-title brochure-modal-title" id="exampleModalLongTitle">Contact our Property Advisor</h5>
<button type="button" class="close" data-dismiss="modal" aria-label="Close" onClick={this.props.HideCallbackModal}>
<span aria-hidden="true">&times;</span>
</button>
</div>
<div class="modal-body brochure-modal-body">
<div className='row'>
<div className="col-sm-12 col-md-12 col-lg-12">

<form style={{ marginTop: -38 }}>
  <div class="form-row align-items-center">
      <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
          {/* <label class="sr-only" for="inlineFormInput">Enter name </label> */}
          <input type="text" class="form-control j_forminputf " id="name" name="name" onChange={this.HandleChange} placeholder="Enter name" value={this.state.name} />
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
          {/* <label class="sr-only" for="inlineFormInput">Enter Email </label> */}
          <input type="text" class="form-control j_forminputf " id="email" name="email" onChange={this.HandleChange} placeholder="Enter Email" value={this.state.email} />
      </div>
      <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                      <label class="sr-only" for="inlineFormInputGroup">Enter mobile number</label>
                      <div class="input-group">
                          <div class="input-group-prepend">
                              <div class="input-group-text bg-light" style={{ borderRadius: 0 }}>+91</div>
                          </div>
                          <input type="text" class="form-control j_forminputf  " maxLength="10" id="mobile" name="mobile" onChange={this.HandleChange} value={this.state.mobile} placeholder="Enter mobile number" />
                      </div>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
         
          <select onChange={this.HandleChange} name="project" id="project" value={this.state.project} class="form-control j_forminputf" >
                                        <option value="-1">Choose project</option>
                                        {this.props.projects.length > 0 ? this.props.projects.map(project =>
                                            <option value={project}>{project}</option>
                                        ) : ''}
                                    </select>
          
      </div>
      {this.state.validationError!==''?
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <div className="errorMessage">{this.state.validationError}</div>
                                    </div>:null}
     
         <button type="button" onClick={this.Submit}  className="u-contact-cta-div">
                                    
                                            <i className="fa fa-phone u-shape-call-icon mr-2"></i>Get a call back
                                   
                                </button>
  </div>
</form>
                            
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        {/* </div> */}

    </div>
        )
    }
}