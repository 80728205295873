import React from 'react';
import $ from 'jquery';
// import $ from 'bootstrap-jquery/dist/js/npm';
//import bootstrap from 'react-bootstrap';


export const PrModalDialog = (props) => {
    /*    alert('in modal'+JSON.stringify(props.prModalData,null,10));
        if(props.prModalData !== undefined || props.prModalData !== null){
            // alert(props.prModalData.prHeading);
    
            /!*let prModalData = {
                prHeading: props.prModalData.prHeading,
                prTitle: props.prModalData.prTitle,
                prMessege: props.prModalData.prMessege,
                CallBackCancel: props.prModalData.CallBackCancel
            };
            alert('kjashk'+
                prModalData
            )*!/
        }*/

    return (
        <div>
            {
                props.prShowModal === 'dialog' ?
                    <div className="modal fade in" id="prModal" role="dialog"
                        style={{ display: 'block', zIndex: 1400, opacity: 1 }}>
                        <div className="modal-dialog" style={{
                            height: '600px',
                            margin: '200px auto auto',
                            verticalAlign: 'middle',
                            width: '500px'
                        }}>
                            <div className="modal-content " style={{ minHeight: '220px', width: '400px' }}>
                                <div className="modal-header">
                                    <button type="button" className="close" onClick={(modalId) => props.CallBackCancel('prModal')}>×</button>
                                    {/* <span id="prModalHeading">{props.prHeading}</span> */}
                                </div>
                                <div id="prModalBody" className="modal-body"
                                    style={{ padding: '20px', maxHeight: '400px', textAlign: 'center' }}>
                                    <img src={require("../images/logo.png")}
                                        style={{ marginBottom: '10px', height: '80px', borderRadius: '5px' }} />
                                    <h4 className="" id="prModalTitle">{props.prTitle}</h4>
                                    {/* <div id="prModalMessage">{props.prMessege}</div> */}
                                    <div id="prModalMessage">{props.prettyMessage}</div>
                                </div>
                                <div className="modal-footer" style={{ textAlign: 'center' }}>
                                    <button className="btn btn-primary" type="button" onClick={(modalId) => props.CallBackCancel('prModal')}
                                        style={{ paddingTop: '5px' }}> Ok
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    :

                    props.prShowModal === 'prConfirmModal' ?
                        <div className="modal fade in" id="prConfirmModal" role="dialog"
                            style={{ display: 'block', zIndex: 1400, opacity: 1 }}>
                            <div className="modal-dialog" style={{
                                height: '600px',
                                margin: '200px auto auto',
                                verticalAlign: 'middle',
                                width: '500px'
                            }}>
                                <div className="modal-content " style={{ minHeight: '220px', width: '400px' }}>
                                    {props.CallBackCancel !== undefined ?
                                        <div className="modal-header">
                                            <button type="button" className="close" onClick={(modalId) => props.CallBackCancel('prConfirmModal')}>×</button>
                                            <span id="prModalHeading">{props.prHeading}</span>
                                        </div>
                                        : ''}
                                    <div id="prModalBody" className="modal-body"
                                        style={{ padding: '20px', maxHeight: '400px', textAlign: 'center' }}>
                                        <img src={require("../images/logo.png")}
                                            style={{ marginBottom: '10px', height: '70px', borderRadius: '5px' }} />
                                        <h4 className="" id="prModalTitle">{props.prTitle}</h4>
                                        <div id="prModalMessage">{props.prMessege}</div>
                                    </div>
                                    <div className="modal-footer" style={{ textAlign: 'center',justifyContent: 'center' }}>
                                        <button className="btn btn-primary pull-left" type="button" onClick={(modalId) => props.CallBackOk('prConfirmModal')}
                                            style={{ paddingTop: '5px' }}> Ok
                                        </button>
                                        {props.CallBackCancel !== undefined ?
                                            <button className="btn btn-primary pull-right" type="button" onClick={(modalId) => props.CallBackCancel('prConfirmModal')}
                                                style={{ paddingTop: '5px' }}> Cancel
                                        </button>
                                            : ''}
                                    </div>
                                </div>
                            </div>
                        </div>

                        :

                        props.prShowModal === 'prAjaxModal' ?

                            <div className="modal fade in" id="prAjaxModal" role="dialog"
                                style={{ display: 'block' }}>
                                <div className="modal-dialog">
                                    <div className="modal-content">
                                        <div className="modal-header">
                                            <button type="button" className="close" onClick={(modalId) => props.CallBackCancel('prAjaxModal')}>×</button>
                                            <span id="prModalHeading">{props.prHeading}</span>
                                        </div>
                                        <div id="prModalBody" className="modal-body">
                                            {props.prAjaxResponse}
                                        </div>
                                        <div className="modal-footer" style={{ textAlign: 'center' }}>
                                            <button className="btn btn-primary" type="button" onClick={(modalId) => props.CallBackCancel('prAjaxModal')}
                                                style={{ paddingTop: '5px' }}> Close
                                        </button>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            :

                            null
            }
            {(document.getElementById('prModal') && (document.getElementById('prModal').style.display === 'none')) ? (document.getElementById('prModal').style.display === 'block') : ''}

        </div>

    );
}