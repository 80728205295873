import React, { Component } from 'react';
import HeaderComponent from '../common/Header';
import FooterComponent from '../common/Footer';
export default class SinglePageLayout extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: false,
            currentModalAction: {},
        };
        this.comp = '';
        this.comp_size = 'md';
        this.routeArray = ['/user/new', '/search', '/planning'];
        this.prev_url = 'init'
    }

    render() {
        let back_color = '#fff';
        for (let i = 0; i < this.routeArray.length; i++) {
            if (this.props.match.path === this.routeArray[i]) {
                back_color = '#fff';
            }
        }
        return (
            <div style={{ background: back_color }}>
                <div id="page-wrapper">
                    <this.props.children {...this.props} />

                    {/*{access_route ?*/}
                    {/*<this.props.children OpenModal={this.handleClickOpen} {...this.props} />*/}
                    {/*: 'You are Not Authorized to access this route.'*/}
                    {/*}*/}
                </div>
                <div>
                    {/* <FooterComponent /> */}
                </div>
            </div>
            // <div>
            //   {/* <div className="container" style={{ background: "url('')  no-repeat fixed center center / cover" }}> */}
            //   <div className="container-fluid">
            //     <div className="row">
            //       <div className="row"><Header {...this.props} /></div>
            //       <div className="row">
            //         <div className="pull-left"><SideBar/></div>
            //         <div className="pull-left"></div>
            //       {this.props.componentprops.children}</div>
            //       {/* <div className="col-sm-2"><SideBar {...this.props}/></div>
            //         <div className="col-sm-10">
            //           <div className="row"><Header {...this.props}/></div>
            //           <div className="row">{this.props.componentprops.children}</div>
            //         </div> */}
            //     </div>
            //   </div>
            //   {/* <div  style={{marginTop: -90 }} className="divBackground"> */}
            //   {/* </div> */}
            //   <div>
            //     <Footer />
            //   </div>
            //   {/* <HomePageLayout contentAreaSearch={<Search {...this.props} SearchTypeValue={this.state.ddlSearchType} SetSearchType={this.GetSearchType} GetPropertySearchData={this.GetProperty} />} contentArea={<div><Ads {...this.props} /><Insight /></div>}  {...this.props} /> */}
            // </div>
        );
    }
}