import React from 'react'

// import WhatsappIcon from '../../assets/images/whatsapp.svg'
// import Loader from '../../assets/images/loader.gif'
export default class ChatPopUp extends React.Component {
    constructor(props) {
        // alert(1)
        super(props)
        this.state = {
            validated: false
        }
    }

    render() {
console.log("props",this.props)
        return (
          <div class="modal contact-modal" tabindex="-1" role="dialog" style={{display:this.props.visible,height:'100%',background:'rgba(0,0,0,0.7)'}}>
          <div class="modal-dialog contact-dialog" role="document" >
            <div class="modal-content">
              <div class="modal-header chat-header-contact-modal">

                <button type="button" class="close" data-dismiss="modal" aria-label="Close" style={{position:'absolute',right:'5%'}} onClick={this.props.HandleClose}>
                  <span aria-hidden="true">&times;</span>
                </button>
                <div className="" style={{width:'100%'}}>
                           <div className="chat-modal-header-main-div"> 
                           {this.props.path == 'http://localhost:3000/' ?
                                        // <img src={require("../../assets/images/" + this.props.logo)} alt={this.props.title} height="100px" width="100px" />
                                        <img src={require("../../assets/images/" + this.props.logo)} alt={this.props.title} className="chat-modal-logo" />
                                        :
                                        <img src={this.props.path + "images/" + this.props.logo} alt={this.props.title} className="chat-modal-logo"/>
                                        // <img src={this.props.path + "images/" + this.props.logo} alt={this.props.title} height="100px" width="100px" />
                                    }
                           {/* <img src={this.props.path + "images/" + this.props.logo} alt={this.props.title} className="chat-modal-logo" /> */}
                           <h3 className="chat-modal-hello">Hello 🙏</h3>
                           <h4 className="schedule-your-free-visit" >Schedule Your Free Site Visit!</h4>
                           </div>
                       </div>
              </div>
              <div class="modal-body">
              <form className="border-2 border-rounded" style={{ padding: '0px 20px' }} >
                            <div class="form-row align-items-center">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <label class="sr-only" for="inlineFormInput">Enter name </label>
                                    <input type="text" class="form-control form-control-contact top-border-radius" value={this.props.txtContactUsername} id="txtContactUsername" name="txtContactUsername" onChange={this.props.HandleChangeContactModal} placeholder="Enter name" />
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <label class="sr-only" for="inlineFormInput">Enter email ID</label>
                                    <input type="email" class="form-control form-control-contact no-border-radius" value={this.props.txtContactEmail} id="txtContactEmail" name="txtContactEmail" onChange={this.props.HandleChangeContactModal} placeholder="Enter email ID" />
                                </div>

                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <label class="sr-only" for="inlineFormInputGroup">Enter mobile number</label>
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <div class="input-group-text bg-light">+91</div>
                                        </div>
                                        <input type="text" class="form-control form-control-contact form-control form-control-contact  no-border-radius" value={this.props.txtContactPhone}  id="txtContactPhone" name="txtContactPhone" onChange={this.props.HandleChangeContactModal} maxLength="10" placeholder="Enter mobile number" style={{ borderBottom: 0 }} />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                                    <select onChange={this.props.HandleChangeContactModal} name="ddlProject" id="ddlProject" value={this.props.mystate.ddlProject} class="form-control form-control-contact proj-appo-input"  style={{ borderBottomLeftRadius: 4,borderBottomRightRadius:4 }}>
                                                        <option value="-1">Choose project</option>
                                                        {this.props.projects !== undefined && this.props.projects.length !== undefined && this.props.projects.length > 0 ? this.props.projects.map(project =>
                                                            <option value={project}>{project}</option>
                                                        ) : ''}
                                                    </select>
                                                </div>
                                                {this.props.validationContactModalMessage!==''?
                                    <div className="col-sm-12 col-md-12 col-lg-12">
                                        <div className="errorMessage">{this.props.validationContactModalMessage}</div>
                                    </div>:null}
                               
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="form-check mb-2 mt-3">
                                        <input class="form-check-input" type="checkbox" id="autoSizingCheck" style={{ opacity: 0.6 }} />
                                        <label class="form-check-label" for="autoSizingCheck" style={{ verticalAlign: 'super', opacity: 0.6 }}>
                                            Send an update on
                                    </label>
                                        <img src={require('../../assets/img/whatsapp-icon.svg')} alt="img upload" style={{ height: 33, marginTop: -15 }} />
                                    </div>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" style={{ paddingBottom: 3 }}>
                                    <button type="button" className="j_btn" style={{width:'100%'}}  onClick={this.props.SendContactModalMail}>Yes I’m Intrested</button>
                                </div>
                                <div class="col-sm-12 col-md-12 col-lg-12" style={{ paddingBottom: 10 }}>
                                    <button type="button" className="j_btn-call" style={{width:'100%'}} ><a href={"tel:+918047193000"} className="j_btn_font">+91-8047-193000</a></button>
                                </div>
                            </div>
                        </form>
              </div>
           
            </div>
          </div>
        </div>
        )
    }
}