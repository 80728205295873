import React from 'react'
export default class Sidebar extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return (
            <div className='sidebar-main mob-only'>
                   <div className="row">
                    <div className="col-12 mb-2">
                        <p className="j_stickyhead" style={{color:'#fff'}}>
                            <a href="#" className="j_anchor header-links sidebar-links" style={{color:'#fff'}}>Home</a></p>
                    </div>
                    <div className="col-12 mb-2">
                        <p className="j_stickyhead">
                            <a href="#projects" className="j_anchor header-links sidebar-links" style={{color:'#fff'}}>Projects</a></p>
                    </div>
                    <div className="col-12 mb-2">
                        <p className="j_stickyhead">
                            <a href="#about" className="j_anchor header-links sidebar-links" style={{color:'#fff'}}>About Us</a></p>
                    </div>
                    {/* <div className="col-12 mb-2">
                        <p className="j_stickyhead">
                            <a href="#about" className="j_anchor header-links" style={{color:'#fff'}}>Hiranandani</a></p>
                    </div> */}
                    <div className="col-12 mb-2">
                        <p className="j_stickyhead">
                            <a href="#contact-footer" className="j_anchor header-links sidebar-links" style={{color:'#fff'}}>Contact</a></p>
                    </div>
  
                    
                    
                </div>
                <div className='close-div-sidebar'>
                    <button style={{background:'transparent',border:0}} onClick={()=>this.props.ShowSideBar()}>
                    <img src={require('../../assets/images/close_icon.svg')} style={{padding:'16px',fontSize:'12px'}}></img>
                    </button>
                </div>

            </div>
        )
    }
}