import React from 'react'
import PropTypes from 'prop-types'
import {
    BrowserRouter,
    Route,
    Switch
} from 'react-router-dom'
import { PrProvider } from '../library/pr-miniredux';
import { prstore } from "../library/stores/pr-store";
import { Pr_Error_Ctrl } from '../library/errors/error-ctrl'
import SinglePageLayout from '../layouts/singlepagelayout';
import HomeCtrl from '../components/Home';

const Root = () => (
    <BrowserRouter basename={process.env.PUBLIC_URL}>
        <div>
            <Switch>
                <Route exact path="/"
                    render={(props) => (<SinglePageLayout children={HomeCtrl} {...props} />)} />
                {/* <Route exact path="/:successful"
                    render={(props) => (<SinglePageLayout children={HomeCtrl} {...props} />)} /> */}
            </Switch>
        </div>
    </BrowserRouter>
    // <PrProvider store={prstore}>
    //     <BrowserRouter basename={process.env.PUBLIC_URL}>
    //         <div>
    //             <Pr_Error_Ctrl />
    //             <Switch>
    //                 <Route exact path="/"
    //                     render={(props) => (<SinglePageLayout children={HomeCtrl} {...props} />)} />
    //             </Switch>
    //         </div>
    //     </BrowserRouter>
    // </PrProvider>
)
Root.propTypes = {
    store: PropTypes.object.isRequired,
}
export default Root