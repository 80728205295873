import React from 'react'
export default class Footer extends React.Component{
    constructor(props){
        super(props)
    }
    render(){
        return(
            <div id='divFooter' className='footer-main-div'>
                <div className='footer-container'>
                    <div className='footer-content'>
                    We are a ZERO brokerage platform with a mission is to simplify the home search. We pride ourselves in our technology, people and process enabled solutions to help customers search, view and move into their Dream home. As Marketing Partners to top developers in Bengaluru, Pune, Gurgaon and Mumbai we have supported over 1500 customers in finding their Dream homes since 2013. Our Team of property advisors help customers through the journey of buying a home - right from sharing property details, face to face or video conference driven discussions, site visits to shortlisted projects, inventory support and best price possible.
                    </div>
                   

                </div>
               

            </div>
        )
    }
}